import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Home from "./pages/Home"
import NewNote from "./pages/NewNote"
import ReadNote from "./pages/ReadNote"
import NotFound from "./pages/NotFound"

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/new/:recipient?" component={NewNote} />
      <Route exact path="/n/:id" component={ReadNote} />
      <Route component={NotFound} />
    </Switch>
  </Router>
)

export default App
