import styled, { css } from "styled-components"

const Container = styled.div`
  display: flex;
  flex-flow: column;

  ${(props) =>
    props.$note &&
    css`
      margin: 1rem auto;
    `}
`

export default Container
