const LAMBDA_PATH = (path) => `/.netlify/functions/${path}`
const DB_PATH = LAMBDA_PATH("note")
const MAIL_PATH = LAMBDA_PATH("mail")

const request = async ({ path, data }) => {
  const options = {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    referrer: "no-referrer",
    body: JSON.stringify(data),
  }

  const res = await fetch(path, options)
  return await res.json()
}

export const create = (note) => request({ path: DB_PATH, data: { action: "create", payload: { note } } })

export const read = (id) => request({ path: DB_PATH, data: { action: "read", payload: { id } } })

export const destroy = (id) => request({ path: DB_PATH, data: { action: "destroy", payload: { id } } })

export const mail = async ({ link, key }) => {
  const res = await fetch("https://api.ipify.org?format=json")
  const { ip } = await res.json()
  return request({ path: MAIL_PATH, data: { link, key, ip, origin: window.location.origin } })
}
