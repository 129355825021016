import { useState } from "react"
import { Textarea, Button, Form } from "../../atoms"

const New = ({ onSubmit }) => {
  const [state, setState] = useState("initial")

  const handleChange = (e) => (e.target.value !== "" ? setState("ready") : setState("initial"))

  const handleSubmit = async (e) => {
    e.preventDefault()
    const note = e.target.querySelector("textarea").value
    setState("submitting")
    try {
      await onSubmit(note)
    } catch (err) {
      console.error(err)
    }
  }

  const isMail = onSubmit.name === "sendMail"
  const instructions = state !== "ready" ? "Enter a Message" : isMail ? "Send" : "Save"

  return (
    <Form onSubmit={handleSubmit} disabled={state === "submitting"}>
      <Textarea disabled={state === "submitting"} onChange={handleChange} placeholder="Write your message here..." />
      <Button $primary disabled={state !== "ready"}>
        {instructions}
      </Button>
    </Form>
  )
}

export default New
