import styled, { css } from "styled-components"

const Button = styled.button`
  cursor: pointer;
  user-select: none;
  outline: none;
  max-width: 80%;
  margin: 1rem auto;
  text-align: center;
  text-decoration: none;

  color: #000;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 500;

  display: inline-block;
  padding: 11px 0;
  width: 100%;

  background: transparent;
  border: 2px solid #000;
  border-radius: 3px;

  transition: 0.03s box-shadow, 0.3s background-color, 0.3s color, 0.3s border, 0.03s ease-in-out transform;
  box-shadow: 0 10px 10px -10px #1c1c1c;

  &:hover {
    background: #000;
    color: #fff;
    box-shadow: 0 12px 10px -10px #1c1c1c;
    text-decoration: underline;
  }

  &:disabled:hover {
    text-decoration: none;
  }

  ${(props) =>
    props.$primary &&
    css`
      background: rgb(64, 180, 73);
      border-color: rgb(64, 180, 73);
      color: #fff;
      box-shadow: 0 2px 10px -3px #1c1c1c;

      &:hover {
        background: rgb(64, 180, 73);
        text-decoration: underline;
        border-color: rgb(64, 180, 73);
        box-shadow: 0 2px 10px -3px #1c1c1c;
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background: #c6c6c6;
      border-color: #c6c6c6;
      color: #383c37;
      box-shadow: 0 2px 10px -3px #1c1c1c;

      &:hover {
        background: #c6c6c6;
        border-color: #c6c6c6;
        color: #383c37;
        box-shadow: 0 2px 10px -3px #1c1c1c;
      }
    `};
`

export default Button
