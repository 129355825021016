import { useState } from "react"
import { encrypt } from "../lib/cipher"
import * as API from "../lib/api"
import { Page, Message, Loading } from "../molecules"
import * as Note from "../organisms/Note"

const NewNote = ({ match: { params } }) => {
  const [state, setState] = useState({
    screen: "new",
    title: "Share An Encrypted Message",
    body:
      "For security reasons, your encrypted message is only kept for up to 7 days and will automatically self-destruct as soon as it is read.",
    link: "",
    recipient: "",
  })

  const createNewEntry = async (note) => {
    setState((state) => ({ ...state, screen: "creating", title: "Processing Message", body: "Encrypting message" }))
    const { cipher, secret } = encrypt(note)
    const { id } = await API.create(cipher)
    return `${window.location.origin}/n/${id}#${secret}`
  }

  const createShareableLink = async (note) => {
    const link = await createNewEntry(note)
    setState((state) => ({ ...state, body: "Generating sharable link" }))

    return setState({
      screen: "created link",
      title: "Success!",
      body: "Share the following link",
      link,
    })
  }

  const sendMail = async (note) => {
    const link = await createNewEntry(note)
    setState((state) => ({
      ...state,
      title: "Sending Message",
      body: "We’ll receive a single-use link to view your message....",
    }))
    // a random value used to determine if email address is on whitelist
    const { recipient: key } = params
    const { recipient } = await API.mail({ key, link })

    return setState({
      screen: "created link and sent mail",
      title: "Success!",
      body: "Your message is on its way...",
      recipient,
      link,
    })
  }

  const Screen = ({ params: { recipient: key } }) => {
    const focusInput = () =>
      setTimeout(() => {
        const t = document.querySelector("textarea")
        if (t) t.focus()
      }, 300)

    switch (state.screen) {
      case "new":
        focusInput()
        return <Note.New onSubmit={key ? sendMail : createShareableLink} />
      case "creating":
        return <Loading>Encrypting message...</Loading>
      case "created link":
        return <Note.Created link={state.link} />
      case "created link and sent mail":
        return <Note.Mailed recipient={state.recipient} />
      default:
        throw new Error(`Ruh-Roh, invalid screen: ${state.screen}`)
    }
  }

  return (
    <Page title="Create a New Message">
      <Message title={state.title} body={state.body} />
      <Screen params={params} />
    </Page>
  )
}

export default NewNote
