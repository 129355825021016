import styled from "styled-components"

const DisplayText = styled.h1`
  margin: 0;
  padding: 0;

  text-transform: ${(props) => (props.$type === "sub" ? "uppercase" : "unset")};
  font-size: ${(props) => (props.$type === "sub" ? "1.25" : "2.5")}rem;
  color: ${(props) => (props.$type === "sub" ? "#40b449" : "#14294e")};
`

export default DisplayText
