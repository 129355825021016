import { useState } from "react"
import styled from "styled-components"

import { Container, Notification } from "../../atoms"
import { Message } from "../../molecules"

const Secret = styled.div`
  min-height: 200px;
  max-height: 300px;
  max-width: 65ch;
  background: rgba(68, 196, 242, 0.1);
  padding: 0.75rem;
  font-size: 1.5rem;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
  overflow: auto;

  * {
    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
  }
  @media (min-width: 600px) {
    &:hover {
      border-radius: 8px;
      transform: scale(1.1);
    }
  }

  @media (max-width: 600px) {
    max-height: 30vh;
  }
`

const Line = styled.div`
  white-space: pre-wrap;
`

const Fragment = styled.span`
  cursor: pointer;
  border-radius: 3px;
  line-height: 2;
  display: inline-block;

  @media (min-width: 600px) {
    &:hover {
      background: #1c0f30;
      color: #fff;
      padding: 0 0.5rem;
      border-radius: 8px;
      transform: scale(1.1);
    }
  }
`

const Show = ({ note }) => {
  const [selected, setCopied] = useState("")

  const copy = (e) => {
    window.getSelection().selectAllChildren(e.target)
    document.execCommand("copy")
    setCopied(e.target.innerText)
    setTimeout(() => setCopied(""), 2000)
  }

  // Split by line and then by word fragment. Concatenates white space with preceding index
  const lines = note.split(/\r?\n/)
  const nestedFragments = lines.map((line) => (line === "" ? [""] : line.match(/\S+[\s]|\S+/g)))

  return (
    <Container $note>
      <Notification disabled={selected === ""}>copied "{selected}" to the clipboard</Notification>
      <Message title="Your Secret Message" body="Hover and click to copy to clipboard" />
      <Secret>
        {nestedFragments.map(
          (line, lineidx) =>
            line && (
              <Line key={`${line}-${lineidx}`}>
                {line.map((part, idx) => (
                  <Fragment key={`${part}-${idx}`} onMouseUp={copy}>
                    {part}
                  </Fragment>
                ))}
              </Line>
            )
        )}
      </Secret>
    </Container>
  )
}

export default Show
