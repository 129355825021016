import styled, { css } from "styled-components"

const Textarea = styled.textarea`
  cursor: initial;

  color: #000;

  line-height: 1.5;
  font-weight: 500;
  font-size: 1rem;

  @media (min-width: 667px) {
    font-size: 1.5rem;
    font-weight: 200;
    min-width: 600px;
  }

  padding: 13px;
  margin: 0;
  width: 100%;

  background: rgba(68, 196, 242, 0.1);
  border: 0;
  border-radius: 3px;
  box-shadow: 0 10px 10px -10px #1c1c1c;

  transition: 0.3s background-color, 0.3s color, 0.3s border;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background: #c6c6c6;
      border-color: #c6c6c6;
      box-shadow: 0 2px 10px -3px #1c1c1c;

      &:hover {
        background: #c6c6c6;
        border-color: #c6c6c6;
        box-shadow: 0 2px 10px -3px #1c1c1c;
      }
    `};
`

export default Textarea
