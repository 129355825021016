import styled from "styled-components"
import { Container } from "../../atoms"

const Message = styled.p`
  max-width: 40rem;
  text-align: center;
  white-space: pre-line;
`

const Mailed = ({ recipient }) => (
  <Container className="Mailed">
    <Message>
      A single-use link to view your message has been sent to <a href={`mailto:${recipient}`}>{recipient}</a>.<br /> As
      soon as we read it, it will automatically be deleted. This keeps your sensitive information much more secure.
    </Message>
  </Container>
)

export default Mailed
