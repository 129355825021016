import { Container } from "../../atoms"
import { CopyLink } from "../../molecules"

const Created = ({ link }) => (
  <Container className="created">
    <CopyLink link={link} />
  </Container>
)

export default Created
