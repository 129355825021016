import { nanoid } from "nanoid"
var crypto = require("crypto")

const generateKey = (secret) => Buffer.from(crypto.createHash("sha256").update(secret).digest("hex"), "hex")

export const encrypt = (message) => {
  const secret = nanoid(6)
  const key = generateKey(secret)
  const iv = crypto.randomBytes(8).toString("hex")
  let cipher = crypto.createCipheriv("aes-256-cbc", key, iv)
  let encrypted = cipher.update(message, "utf8", "base64")
  encrypted += cipher.final("base64")

  return {
    cipher: iv + ":" + encrypted,
    secret,
  }
}

export const decrypt = ({ cipher, secret }) => {
  const key = generateKey(secret)
  const [iv, encryptedText] = cipher.split(":")
  let decipher = crypto.createDecipheriv("aes-256-cbc", key, iv)
  let decrypted = decipher.update(encryptedText, "base64", "utf8")
  return decrypted + decipher.final("utf8")
}
