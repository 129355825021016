import { Page, Message } from "../molecules"
import { Button } from "../atoms"
import { Link } from "react-router-dom"

const NotFound = () => (
  <Page title="404 - Not Found">
    <Message title="404 - Not Found" body={`Nothing to see here.`}>
      <Button as={Link} $primary to="/">
        Return Home
      </Button>
    </Message>
  </Page>
)

export default NotFound
