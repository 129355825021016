import { useState } from "react"
import { decrypt } from "../lib/cipher"
import * as API from "../lib/api"
import { Page, Message, Loading } from "../molecules"
import * as Note from "../organisms/Note"

const ReadNote = ({ match: { params }, location: { hash } }) => {
  const Screen = ({ params, hash }) => {
    const [state, setState] = useState({ screen: "initial" })

    const fetchNote = async ({ id, secret }) => {
      setState({ screen: "fetching" })

      try {
        const { note } = await API.read(id)
        if (note) {
          const decrypted = decrypt({ secret, cipher: note })
          setState({ screen: "fetched", note: decrypted })
        } else {
          setState({ screen: "not-found" })
        }
      } catch (err) {
        console.error(err)
        setState({ screen: "error", error: err })
      }
    }

    switch (state.screen) {
      case "initial":
        fetchNote({ id: params.id, secret: hash.slice(1) })
        return <Loading>Connecting...</Loading>
      case "fetching":
        return <Loading>Fetching note...</Loading>
      case "fetched":
        return <Note.Show note={state.note} />
      case "error":
        return (
          <Message
            title="Cincinnati, we have a problem."
            body="An error has occurred. As a precaution, the message has been deleted."
          />
        )
      case "not-found":
        return (
          <Message
            title="Not Found"
            body="If you expected to see the message here, it has most likely already been read."
          />
        )
      default:
        throw new Error(`There's no corresponding case for this screen: ${state.screen}`)
    }
  }

  return (
    <Page title="Read Your Encrypted Message">
      <Screen params={params} hash={hash} />
    </Page>
  )
}

export default ReadNote
