import { useRef, useState } from "react"
import styled from "styled-components"
import { Button, Container, Notification } from "../atoms"

const Link = styled.div`
  font-size: 1.1rem;
  padding: 2rem;
  text-align: center;
  font-family: monospace;
  cursor: pointer;

  @media (min-width: 600px) {
    font-size: 1.75rem;
  }
`

const CopyLink = ({ link }) => {
  const linkRef = useRef(null)
  const initialState = { msg: "Copy", status: "initial", disabled: false }
  const [{ msg, status, disabled }, setState] = useState(initialState)

  const selectLink = () => window.getSelection().selectAllChildren(linkRef.current)

  const copyLink = () => {
    selectLink()
    // Prevent stacking timeouts
    if (status === "timeout") return selectLink()

    document.execCommand("copy")
    setState({ msg: "Copied!", status: "timeout", disabled: true })

    setTimeout(() => {
      window.getSelection().removeAllRanges()
      setState(initialState)
    }, 2000)
  }

  return (
    <Container className="copy-link">
      <Notification disabled={status !== "timeout"}>copied "{link}" to the clipboard</Notification>
      <Link ref={linkRef} onClick={copyLink}>
        {link}
      </Link>
      <Button disabled={disabled} $primary onClick={copyLink}>
        {msg}
      </Button>
    </Container>
  )
}

export default CopyLink
