import styled, { keyframes } from "styled-components"

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const Status = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 3px;
  padding: 1rem;
  color: #fff;
  background: #08162e;
  animation: 2s ${fadeOut} cubic-bezier(0.95, 0.07, 0.35, 0.37) forwards;
`

const Notification = ({ disabled, children }) => {
  if (disabled) return null

  return <Status>{children}</Status>
}

export default Notification
