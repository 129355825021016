import styled from "styled-components"
import { DisplayText } from "../atoms"

const MessageContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: ${(props) => (props.msgtitle && props.body ? ".5fr .5fr" : "1fr")};
  align-items: center;
  text-align: center;
  padding: 1rem;
  max-width: 40rem;
  margin: 0 auto;
`

const Message = ({ title, body, children }) => (
  <MessageContainer msgtitle={title} body={body}>
    <DisplayText>{title}</DisplayText>
    <DisplayText as="h4" $type="sub" dangerouslySetInnerHTML={{ __html: body }} />
    {children}
  </MessageContainer>
)

export default Message
