import styled from "styled-components"
import { Link } from "react-router-dom"

import logoImg, { ReactComponent as Svg } from "../img/2060-digital-logo.svg"

const Logo = ({ className }) => (
  <Link className={className} to="/">
    <Svg src={logoImg} alt="2060 Digital" />
  </Link>
)

const styledLogo = styled(Logo)`
  text-decoration: none;
  margin: 0 auto;
  width: 150px;
  align-self: center;

  @media (min-width: 600px) {
    width: 225px;
  }
`

export default styledLogo
