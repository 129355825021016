import { Page, Message } from "../molecules"
import { Button } from "../atoms"
import { Link } from "react-router-dom"

const Home = () => (
  <Page title="Share An Encrypted Message">
    <Message
      title="Share An Encrypted Message"
      body="For security reasons, your encrypted message is only kept for up to 7 days and will automatically self-destruct as soon as it is read."
    />
    <Button as={Link} $primary to="/new">
      Create Message
    </Button>
  </Page>
)

export default Home
