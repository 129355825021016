import Helmet from "react-helmet"
import { Container, Logo, Page as PageWrapper } from "../atoms"

const Page = ({ title, children }) => (
  <PageWrapper>
    <Helmet title={title} />
    <Container>
      <Logo />
      {children}
    </Container>
  </PageWrapper>
)

export default Page
