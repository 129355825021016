import styled, { keyframes } from "styled-components"
import Container from "../atoms/Container"

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Pulsing = styled.div`
  animation: ${pulse} 0.25s infinite alternate;
  text-align: center;
  padding-top: 3rem;
`

const Loading = ({ children }) => (
  <Container>
    <Pulsing>{children}</Pulsing>
  </Container>
)

export default Loading
