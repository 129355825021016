import styled from "styled-components"

const Form = styled.form`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  padding: 0;
  text-align: center;

  max-height: 50vh;
  width: 100%;
`

export default Form
